export const updateMessageAttributes = `
        id
        conversationId
        senderId
        recipientId
        readByRecipient
        readByRecipientAt
        displayOwnAsUnread
        isDeleted`;

export const messageAttributes = `
        __typename
        id
        senderId
        recipientId
        conversationId
        encryptedTextContent
        readByRecipient
        readByRecipientAt
        createdAt
        displayAsPharmacyMessage
        displayOwnAsUnread
        isDeleted
        automatic
        appointmentChangedDateTime
        initialMessage
        media {
          __typename
          id
          mediaType
          encryptedFilename
          encryptedLink
          encryptedPreviewLink
          encryptedImage
          encryptedText
          exifOrientation
          videoCallUrls {
            senderUrl
            recipientUrl
          }
          encryptedShopProduct
          encryptedERezept
          encryptedCalendarEvent
        }
        conversation {
          __typename
          encryptedAppointment
          encryptedShoppingCart
          appointmentId
        }`;

export const pharmacyAttributes = `
        __typename
        id
        cognitoId
        sanacorpCustomerId
        contactEmail
        contactName
        description
        name
        telephone
        website
        openingHours
        notdienst {
          von
          bis
        }
        shippingDetails
        privacyPolicyLink
        onlineShop
        detailsPage
        disabled
        address {
          __typename
          postalCode
          additionalInfo
          city
          country
          houseNumber
          street
          geo {
            __typename
            lat
            lon
          }
        }`;

export const pharmacyChatUserAttributes = `
      __typename
      pharmacyId
      cognitoId
      cognitoUsername
      publicKey
      encryptedPrivateKey
      encryptionSalt
      userType
      userStatus
      pushNotificationSubscription
      pushNotificationRepetitionInterval
      emailNotification
      favoritedByAppUserIds
      favoritedByAppUserCountAtStartOfMonth
      customAppointmentTypes
      hiddenDefaultAppointmentTypes
      migratedToAppointmentsV2
      initialMessages {
        askQuestion
        askQuestionClosed
        preorder
        preorderClosed
        productQuery
        productQueryClosed
      }
      addons {
          __typename
          type
          status
          subscriptionTimestamp
          expirationTimestamp
      }
      productCardsSentLast30Days
      appNotificationSettings {
          token
          isEnabled
          onlyDuringOpeningTimes
      }
      resetKeyPairOnNextLogin
      holidays {
          state
          customHolidays
      }
      vacation {
          from
          until
      }
      emergencyOpeningStatus
      `;

export const endUserAttributes = `
        __typename
        cognitoId
        cognitoUsername
        chatname
        publicKey
        userType
        encryptionSalt
        isGuest
        isAppUser
        contentGroup
        registered
        userStatus
        deleteAccountAt
        consentToDataTransfer
        consentToDataTransferVersion
        pushNotificationSubscription
        favoritePharmaciesIds
        favoriteAppPharmaciesIds
        emailNotification
        ads
        guestNotificationEmail
        appNotificationSettings {
          token
          isEnabled
          closedTicketNotificationsEnabled
        }
        favoritePharmacies {
          ${pharmacyAttributes}
          pharmacyChatUser {
            __typename
            pharmacyId
            cognitoId
            publicKey
            userType
            userStatus
          }
        }`;

export const userConversationAttributes = `
      __typename
      id
      conversationLink
      encryptedChatPartnerNickname
      ownerId
      conversationId
      conversation {
        firstSegmentId
      }
`;

export const appointmentAttributes = `
      __typename
      pharmacyCognitoId
      availableAppointmentTypes
      selectedAppointmentType
      dateTime
      durationMinutes
      isBooked
      bookedByName
      bookedByEmail
      bookedByNameIsEncrypted
      encryptedConversationId
      encryptedNote
`;

export const appointmentV2Attributes = `
      __typename
      pharmacyCognitoId
      enduserCognitoId
      conversationId
      id
      dateTime
      telephone
      email
      appointmentTypeId
      mandatoryCustomerFields {
        age {
          pharmacy
          enduser
        }
        name {
          pharmacy
          enduser
        }
        sex {
          pharmacy
          enduser
        }
      }
      additionalInformation
      durationInMinutes
      name
      isCancelled
      attachments {
        s3Key
        name
        size
      }
      `;

export const appointmentV2TypeAttributes = `   
      __typename
      pharmacyCognitoId
      id
      name
      durationInMinutes
      attachments {
        s3Key
        name
        size
      }
      color
      mandatoryCustomerFields
      additionalInformation
      automaticMessage
      leadTimeInHours
      timeslots {
        from
        until
      }
      ignoreOpeningHours
      ignoreBreakTimes
      startDate
      endDate
      isActive
      isImportedAppointmentType`;

export const consentAttributes = `
      __typename
      userCognitoId
      timestamp
      consentType
      intent
      appVersion
      pharmacyId
      pharmacy {
        ${pharmacyAttributes}
      }
`;

export const feedbackAttributes = `
      __typename
      id
      userCognitoId
      timestamp
      rating
      device
      frontend
      comment
      frontendConversationId
      chatPartnerCognitoId
      chatPartnerCustomerId
`;

export const chatPartnerMetadataAttributes = `
      __typename
      cognitoId
      chatPartnerId
      encryptedChatPartnerNickname
      chatPartnerChatnameHistory {
        encryptedChatname
        encryptedFirstName
        encryptedLastName
        timestamp
      }
      isSuspended
      isUnlockedForDemoPharmacy
`;
