import { inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { combineLatest, concatMap, firstValueFrom, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { UnreadMessagesCountService } from '../../../../../../../../libs/common/resources/src/services/unread-messages-count.service';
import { Logger } from '../../../../../../../../libs/essentials/util/src/logger';
import { MeaState } from '../../../../store/state/mea.state';
import { selectSoundNotification, selectSoundRepetition } from '../../selectors/home.selectors';

const logger = new Logger('AudioService');

@Injectable({ providedIn: 'root' })
export class AudioService implements OnDestroy {
  private store: Store<MeaState> = inject(Store);
  private unreadMessagesCountService = inject(UnreadMessagesCountService);
  private platform = inject(Platform);

  audio: HTMLMediaElement | undefined;

  private soundNotification$ = this.store.select(selectSoundNotification);
  private soundRepetition$ = this.store.select(selectSoundRepetition);
  private hasUnreadMessages$ = this.unreadMessagesCountService.unreadMessagesTotalCount$.pipe(
    map((unreadMessagesTotalCount) => unreadMessagesTotalCount > 0),
    distinctUntilChanged()
  );
  private stopTimer$ = new Subject<void>();

  constructor() {
    const isSafari =
      this.platform.testUserAgent('Safari') &&
      !this.platform.testUserAgent('Chrome') &&
      !this.platform.testUserAgent('Chromium');
    if (!isSafari) {
      logger.info('not in Safari, initializing audio immediately');
      this.initAudio();
    }
    combineLatest([this.hasUnreadMessages$, this.soundNotification$, this.soundRepetition$])
      .pipe(
        concatMap(async ([hasUnreadMessages, soundNotification, soundRepetition]) =>
          hasUnreadMessages && soundNotification && soundRepetition !== -1 ? this.resetTimer() : this.stopTimer()
        ),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.stopTimer$.next();
    this.stopTimer$.complete();
  }

  async playSoundAndResetTimer() {
    await this.playSound();
    await this.resetTimer();
  }

  /* Init audio playback if in Safari, since it is only possible on interaction */
  initAudioOnInteraction() {
    if (!this.audio) {
      this.initAudio();
    }
  }

  private initAudio() {
    logger.info('Initializing audio');
    this.audio = new Audio();
    this.audio.src = '../../../assets/audio/notification.wav';
    this.audio.load();
  }

  private async playSound() {
    try {
      const soundNotification = await firstValueFrom(this.soundNotification$);
      if (soundNotification && this.audio) {
        this.audio.paused ? await this.audio.play() : (this.audio.currentTime = 0);
      }
    } catch (e) {
      logger.info('Failed to play audio sound', e);
    }
  }

  private async startTimer() {
    const soundNotification = await firstValueFrom(this.soundNotification$);
    const soundRepetition = await firstValueFrom(this.soundRepetition$);
    if (soundRepetition !== -1 && soundNotification) {
      this.initTimer(soundRepetition * 1000);
    }
  }

  private stopTimer() {
    this.stopTimer$.next();
  }

  private async resetTimer() {
    this.stopTimer();
    await this.startTimer();
  }

  private initTimer(soundRepetitionInMilliSeconds: number) {
    timer(soundRepetitionInMilliSeconds, soundRepetitionInMilliSeconds)
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(() => this.playSound());
  }
}
